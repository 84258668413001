<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container">
        <div class="row align-items-end r-1" style="height: 200px;">
            <div class="col-sm-4 reg-img">
                <img src="../../assets/Regalix_logo.png" alt="regalix">
            </div>
        </div>
       
        <div id="g-login" class="row mt-5">
            <div class="col-8 col-sm-6 col-md-6 col-lg-4 col-xl-3 g-login" (click)="signInWithGoogle()">
                <img src="../../assets/google.png" alt="google-icon">
                <p>
                    Sign in with Regalix
                </p>
            </div>
        </div>
    
    </div>
</body>
</html>