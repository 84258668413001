import { Component, Input, OnInit } from '@angular/core';
import { ScriptService } from 'src/app/services/script.service';
declare var twttr: any;
@Component({
  selector: 'app-company-events',
  templateUrl: './company-events.component.html',
  styleUrls: ['./company-events.component.css']
})
export class CompanyEventsComponent implements OnInit {
  @Input() selectedDate = new Date();
  @Input() filteredEvents;
  @Input() events;
  @Input() videoLink;
  @Input() appreciations;
  constructor(public scriptService: ScriptService) { }

  ngOnInit(): void {
    this.twitfed();
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate()
    return [month, day].join('-');
  }
  format(date) {
    var d = new Date(date),
      year = '' + (d.getFullYear()),
      month = '' + (d.getMonth() + 1),
      m = (month.length > 1) ? month : '0' + month,
      day = '' + d.getDate(),
      x = (day.length > 1) ? day : '0' + day
    return [year, m, x].join('-');
  }
  calendarSelectedDate(date) {
    let y = this.format(date)
    let monthDay = this.formatDate(date)
    this.filteredEvents = [];
    this.filteredEvents = this.events.filter(data => data.event_date === y)
  }
  async twitfed() {
    await this.scriptService.load('facebook');
    twttr.widgets.load();
  }
}
