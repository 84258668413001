import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tick } from '@angular/core/testing';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  backendApi = environment.apiBaseUrl
  constructor(private http: HttpClient) { }

  getImageFroms3(url) {
    return this.http.get(url, {responseType: 'blob'})
  }

  getVideoFroms3(url) {
    return this.http.get(url, {responseType: 'blob'})
  }
  getUserData() {
    return this.http.get(this.backendApi + 'user-dashboard')
  }
  getFilePresURL(id) {
    return this.http.get(this.backendApi + 'user-dashboard?emp_manual_id=' + id)
  }
  getTokenFlexie() {
    return this.http.get(this.backendApi + 'flexie-login')
  }
  getNewsFlash() {
    return this.http.get('https://www.regalix.com/wp-json/news/v1/posts?pageNumber=1&totalCount=10')
  }
}