import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2'


import { GoogleAuthService } from '../services/google-auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user: SocialUser;
  loggedIn: boolean;

  constructor(private authService: SocialAuthService,
              private gAth: GoogleAuthService,
              private router: Router,
              private ngxService: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.ngxService.stop();
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    const role = loggedUser? loggedUser.roles : undefined;
    if(role) {
      if(role == 'Employee') {
        this.router.navigate(['/user'])
      } else if(role == 'Admin') {
        this.router.navigate(['/admin'])
      } else {
        this.router.navigate([`/`]);
      }
    }
  }

  signInWithGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((user) => {
        if (user.email.indexOf('regalix-inc') !== -1) {
          this.gAth.idpPermission({'idToken': user.idToken}, user.email)
          .subscribe((res) => {
            if (res['status']) {
              localStorage.setItem('user', JSON.stringify(res['data']));
              this.router.navigate(['user']);
            } else {
              Swal.fire({
                text: 'You do not have access to the application',
                icon: 'warning',
              }).then(() => {
                this.router.navigate(['login']);
              })
            }
          },
          (error) => {
            this.router.navigate(['login']);
          })
         }
        else {
          Swal.fire({
            text: 'Please login with regalix ID',
            icon: 'warning',
          }).then(() => {
            this.router.navigate(['login']);
          })
        }

      })
  }

 
}
