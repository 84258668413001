<div class="banner">
    <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
        <mdb-carousel-item *ngFor="let banner of banners; let i=index;">
            <ng-container *ngIf="banner.image;else other_link">
                <a *ngIf="banner.type == 'image' && banner.link" href="{{banner.link}}" target="_blank"><img
                        [attr.src]="banner.image" alt="assets/Desktop.png">
                </a>
            </ng-container>
            <ng-template #other_link><img *ngIf="banner.type == 'image' && banner.link" src="assets/loading.gif">
            </ng-template>
            <ng-container *ngIf="banner.image;else other_vid">
                <a *ngIf="banner.type == 'video'"><img [attr.src]="banner.image" alt="video" (click)="videoModal1.show()">
                    <div mdbModal #videoModal1="mdbModal" class="modal fade" tabindex="-1" role="dialog"
                    aria-labelledby="myvideoModal1Label" aria-hidden="true">
                    <div class="close_icon" (click)="videoModal1.hide()"><i class="fas fa-times"></i></div>
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-body mb-0 p-0">
                                <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                                    <video  class="video-fluid" [attr.src]="banner.s3_video_url" crossOrigin="anonymous" controls>
                                        Your browser does not support HTML5 video.
                                    </video>
                                </div>
                            </div>
        
                        </div>
                    </div>
                </div>
                </a>
            </ng-container>
            <ng-template #other_vid><img *ngIf="banner.type == 'video'" src="assets/loading.gif"></ng-template>
 
            <ng-container *ngIf="banner.image;else other_content">
                <img *ngIf="banner.type == 'image' && !banner.link" [attr.src]="banner.image" alt="{{i+1}} slide">
            </ng-container>
            <ng-template #other_content><img *ngIf="banner.type == 'image' && !banner.link" src="assets/loading.gif">
            </ng-template>
        </mdb-carousel-item>
    </mdb-carousel>
    <div class="nobannerimages" *ngIf="banners.length == 0">
        No banner Images
    </div>
</div>