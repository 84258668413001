<div class="pagefooter">
    <div class="ltext">
        <div class="txt">© 2020 Regalix.inc All Rights Reserved.</div>
        <div class="txt">Privacy Policy</div>
        <div class="txt">Terms of Service</div>
    </div>
    <div class="rtext">
        <div class="txt">Follow us: <a href="https://twitter.com/Regalix" target="_blank"> Twitter |</a> <a
                href="https://www.facebook.com/Regalix" target="_blank"> Facebook |</a> <a
                href="https://www.youtube.com/user/regalixinfo" target="_blank"> Youtube |</a> <a
                href="https://www.instagram.com/regalix_/" target="_blank"> Instagram |</a> <a
                href="https://www.linkedin.com/company/regalix/mycompany/" target="_blank"> Linkedin </a></div>
    </div>
</div>