<div class="container-fluid">
    <div class="d-flex flex-row-reverse social">
        <div class="headSocLinks">
            <strong> Follow us: </strong> <a href="https://twitter.com/Regalix" target="_blank">Twiter</a> | <a
                href="https://www.facebook.com/Regalix" target="_blank">Facebook</a> | <a
                href="https://www.youtube.com/user/regalixinfo" target="_blank">Youtube</a> | <a
                href="https://www.instagram.com/regalix_/" target="_blank">Instagram</a> |
            <a href="https://www.linkedin.com/company/regalix/mycompany/" target="_blank">Linkedin</a>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light">
                <button class="navbar-toggler" type="button" aria-label="Toggle navigation">
                    <!-- <span class="navbar-toggler-icon"></span> -->
                    <img src="../../../assets/Group 13768.svg" *ngIf="!showCloseIcon" (click)="showMobileSideNav()">
                    <!-- <span class="burgerIcon" *ngIf="!showCloseIcon" (click)="showMobileSideNav()"><i
                            class="fa fa-bars"></i></span> -->
                    <span class="closeIcon" *ngIf="showCloseIcon" (click)="hideMobileSideNav()"><i
                            class="fa fa-times"></i></span>
                </button>
                <img src="../../../assets/Group 13747.svg" class="navbar-brand" alt="" style="height: 56px;">
                <img class="res-img" mat-button [matMenuTriggerFor]="menu" id="user_profile" src="{{userInfo.image}}"
                    alt="user-profile">


                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto" style="align-items: baseline;">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                style="font-size: 20px;">
                                Tools and Products
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <div class="row mt-3 mb-3">

                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="ml-5 pl-2">
                                            <h1 style="font-size: 35px; font-weight: 500">Tools</h1>
                                            <div *ngFor="let product of tools; let i=index;">
                                                <a class="dropdown-item mb-3" href="{{product.url}}"
                                                    target="_blank">{{product.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div id="verticle_line" class="col-5">
                                        <a href="https://intranet.regalix.com/" target="_blank">Tractivity</a>
                                    </div> -->
                                </div>


                            </div>
                        </li>
                        <li class="nav-item ml-4">
                            <a class="nav-link" href="https://regalix.flexiele.com/" target="_blank">
                                <img src="../../../assets/Image 273@2x.png" class="navbar-brand" alt=""
                                    style="height: 41px;">
                            </a>
                        </li>

                        <li class="nav-item ml-4">
                            <a class="nav-link" href="https://chat.google.com/room/AAAA3AzKBaE" target="_blank">
                                <img src="../../../assets/Group 13754@2x.png" class="navbar-brand" alt=""
                                    style="height: 37px;">
                            </a>
                        </li>

                    </ul>
                    <ul class="navbar-nav" style="align-items: baseline;">
                        <li class="nav-item ml-4">
                            <img src="../../../assets/Regalix_logo.svg" class="navbar-brand" alt=""
                                style="height: 32px;">
                        </li>
                        <li class="nav-item ml-4" style="margin: 0; color: #E62049; font-size: 20px;">
                            Hi
                        </li>
                        <li class="nav-item ml-4" style="font-size: 20px;">{{userInfo.name}}</li>
                        <li class="nav-item ml-4"><img mat-button [matMenuTriggerFor]="menu" id="user_profile"
                                src="{{userInfo.image}}" alt="user-profile"></li>
                    </ul>
                    <mat-menu #menu="matMenu">
                        <div>
                            <button mat-menu-item (click)="logOut()">Logout</button>
                        </div>
                    </mat-menu>
                </div>
            </nav>
        </div>

    </div>
</div>
<div class="mobileSideNavBackgroundPatch" (click)="hideMobileSideNav()" *ngIf="mobileSideNav"></div>
<div class="sideNav-section" *ngIf="mobileSideNav">
    <div class="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0 commonStyle float-left sideNavContent-section">
        <ul class="navbar-nav mr-auto" style="align-items: baseline;">
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" style="font-size: 20px;">
                    Tools and Products
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div class="row mt-3 mb-3">

                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div *ngFor="let product of tools_products; let i=index;">
                                <div class="ml-5 pl-2">
                                    <div *ngIf="product.links.length > 1">
                                        <h1 style="font-size: 35px; font-weight: 500">{{product.name}}</h1>
                                        <div *ngFor="let link of product.links">
                                            <a class="dropdown-item mb-3" href="{{link.href}}"
                                                target="_blank">{{link.a_name}}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-5 pl-2 mt-2"
                                    *ngIf="product.links.length == 1 && i <= (tools_products.length-2) ">
                                    <h1 style="margin-bottom: 4px;"><a href="{{product.links[0].href}}"
                                            target="_blank">{{product.name}}</a></h1>
                                    <a class="some_text" href="{{product.links[0].href}}"
                                        target="_blank">{{product.links[0].a_name}}</a>
                                </div>

                                <div class="ml-5 pl-2 mt-3 mb-5"
                                    *ngIf="product.links.length == 1 && i == (tools_products.length-1) ">
                                    <h1 style="margin-bottom: 4px;"><a href="{{product.links[0].href}}"
                                            target="_blank">{{product.name}}</a></h1>
                                    <a class="some_text" href="{{product.links[0].href}}"
                                        target="_blank">{{product.links[0].a_name}}</a>
                                </div>

                            </div>
                        </div>
                        <div id="verticle_line" class="col-5">
                            <a href="https://intranet.regalix.com/" target="_blank">Tractivity</a>
                        </div>
                    </div>


                </div>
            </li>
            <li class="nav-item ml-4">
                <a class="nav-link" href="https://regalix.flexiele.com/" target="_blank">
                    <img src="../../../assets/Image 273@2x.png" class="navbar-brand" alt="" style="height: 41px;">
                </a>
            </li>

            <li class="nav-item ml-4">
                <a class="nav-link" href="https://chat.google.com/room/AAAA3AzKBaE" target="_blank">
                    <img src="../../../assets/Group 13754@2x.png" class="navbar-brand" alt="" style="height: 37px;">
                </a>
            </li>

        </ul>
    </div>
</div>