<div class="header">
    <div class="top-menu">
        Follow us: <a href="https://twitter.com/Regalix" target="_blank"> Twiter |</a> <a
            href="https://www.facebook.com/Regalix" target="_blank"> Facebook |</a> <a
            href="https://www.youtube.com/user/regalixinfo" target="_blank"> Youtube |</a> <a
            href="https://www.instagram.com/regalix_/" target="_blank"> Instagram |</a>
        <a href="https://www.linkedin.com/company/regalix/mycompany/" target="_blank"> Linkedin </a>
    </div>
    <div class="header-menu">
        <div class="logomenu">
            <div class="menu"><img class="logo" src="assets/myhub.svg" alt="app-logo"> </div>
            <div class="menu" [matMenuTriggerFor]="belowMenu">Tools <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
            <mat-menu #belowMenu="matMenu" yPosition="below">
                <ng-container *ngFor="let product of tools; let i=index;">
                    <a mat-menu-item href="{{product.url}}" target="_blank">{{product.name}}</a>
                </ng-container>
            </mat-menu>
            <div class="vl"></div>
            <div class="menu">
                <!-- <form #flexieForm="ngForm" (ngSubmit)="flexieLogin(flexieForm, $event)" method="post" action="{{flexieURL}}"
                    enctype="application/x-www-form-urlencoded" target="_blank">
                    <input type="hidden" name="credToken" value="{{tokenFlexieLogin}}">
                    <button type="submit" class="forwhitebtntext">
                        <img class="flexiele" src="assets/Image 273.png" alt="app-logo">
                    </button>
                </form> -->
                <a href="https://regalix.darwinbox.com/ms/vibe/home/posts/all" target="_blank">
                    <img class="flexiele" src="assets/darwin-box.png" alt="app-logo">
                </a>
            </div>
            <div class="vl"></div>
            <div class="menu"><a href="https://chat.google.com/room/AAAA3AzKBaE" target="_blank"><img
                        class="coffee-longue" src="assets/Group 13754.png" alt="app-logo"> </a></div>
        </div>
        <div class="user-menu">
            <div class="u-menu"><img class="c_logo" src="assets/Regalix-Logo.svg" alt="app-logo"></div>
            <div class="u-menu" style="color: #E62049;">Hi</div>
            <div class="u-menu" style="color: #352B56;">{{userInfo.name}}</div>
            <div class="u-menu" style="margin-right:0"><img class="user-img" src="{{userInfo.image}}" alt="user-profile"
                    [matMenuTriggerFor]="menu"></div>
            <mat-menu #menu="matMenu">
                <div>
                    <a *ngIf="isAdmin" mat-menu-item (click)="navigateToAdminPage()">Admin</a>
                    <a mat-menu-item (click)="logOut()">Logout</a>
                </div>
            </mat-menu>
        </div>
    </div>
</div>


<!-- header for above 768px ends here -->
<header class="resp-header">
    <div class="profile"><img class="user-img" src="{{userInfo.image}}" alt="user-profile" [matMenuTriggerFor]="menu">
    </div>
    <div class="logo"><img src="assets/myhub.svg" alt="app-logo" routerLink="/"></div>
    <input class="menu-btn" type="checkbox" id="menu-btn" />
    <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
    <ul class="menu">
        <li [matMenuTriggerFor]="belowMenu"><a style="display: flex;">Tools <mat-icon>keyboard_arrow_down</mat-icon></a>
        </li>
        <li><a href="https://regalix.darwinbox.com/ms/vibe/home/posts/all" target="_blank">
                <img class="flexiele" src="assets/darwin-box.png" alt="app-logo">
            </a></li>
        <li><a href="https://chat.google.com/room/AAAA3AzKBaE" target="_blank">
                <div style="width: 130px;">
                    <img width="100%" src="assets/Group 13754.png" alt="app-logo">
                </div>
            </a>

        </li>
    </ul>
</header>
<!-- header for below 768px -->