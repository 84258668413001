<div class="notification">
    <div class="notificationhead">
        <img src="assets/notification.svg" alt="bell-icon">
        Buzz-Feed
    </div>
    <div class="notificationstxt">
        <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
            <mdb-carousel-item *ngFor="let q of notification; let i=index;">
                <a href="{{q.link}}" target="_blank">{{q.text}}</a>
            </mdb-carousel-item>
            <mdb-carousel-item *ngIf="notification.length == 0" style="text-align: center;">
                No Buzz-Feed
            </mdb-carousel-item>
        </mdb-carousel>
    </div>
</div>
