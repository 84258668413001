import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { GoogleAuthService } from '../google-auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/internal/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector, public router: Router, public dialog: MatDialog, private ngxService: NgxUiLoaderService, private spinner: NgxSpinnerService) { }
  alertDialog(obj) {
    this.dialog.open(DialogComponent, {
      width: '400px',
      data: obj
    });
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(GoogleAuthService);
    const token = authService.getAccessToken();
    const s3URL = environment.s3BucketURL;
    if (token) {
      if ((req.url.indexOf(s3URL) === 0)) {
        return next.handle(req);
      }
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token['access_token']
        }
      });
    } else {
      this.router.navigate([`/`]);
    }
    return next.handle(req).pipe(catchError((error, caught) => {
      // intercept the respons error and displace it to the console
      this.handleAuthError(error);
      return of(error);
    }) as any);
  }
  public async handleAuthError(err: HttpErrorResponse) {
    // handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      // navigate /delete cookies or whatever
      this.spinner.hide();
      this.ngxService.stop()
      localStorage.clear();
      this.router.navigate([`/`]);
      const info = {
        msg: 'Your session is got expired please login to continue',
        type: 'Alert',
      };
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '400px',
        data: info
      });
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message);
    }
    if (err.status === 400) {
      this.spinner.hide();
      this.ngxService.stop()
      const info = {
        msg: 'Bad request',
        type: 'Alert',
      };
      this.alertDialog(info);
    }
    if (err.status === 500) {
      this.spinner.hide();
      this.ngxService.stop();
      localStorage.clear();
      this.router.navigate([`/`]);
      const info = {
        msg: 'Your session is got expired please login to continue',
        type: 'Alert',
      };
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '400px',
        data: info
      });
    }
    throw err;
  }
}

