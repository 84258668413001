<ngx-spinner bdColor="rgba(152, 152, 250)" [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading" style="color: white;">Please Wait..</p>
</ngx-spinner>
<div class="main">
    <app-header [tools]="tools" [flexieURL] = "flexieURL" [tokenFlexieLogin] = "tokenFlexieLogin"></app-header>
    <div class="bannerarea" *ngIf="empOverallData">
        <app-banner [banners]="banners"></app-banner>
        <div class="quotenoti">
            <app-emp-quotes [empQuotes]="empQuotes"></app-emp-quotes>
            <app-company-notifications [notification]="notification"></app-company-notifications>
        </div>
    </div>
    <div *ngIf="empOverallData">
        <app-company-events [events]="events" [videoLink]="videoLink" [appreciations]="appreciations"
            [selectedDate]="selectedDate" [filteredEvents]="filteredEvents">
        </app-company-events>
        <app-latest-update [faqs]="faqs" [empManuals]="empManuals" [newJoinees] = "newJoinees"></app-latest-update>
    </div>
   <app-social-update></app-social-update>
    <div *ngIf="empOverallData">
        <app-pic-gallery [gallery]="gallery"></app-pic-gallery>
    </div>
</div>
<app-footer></app-footer>
