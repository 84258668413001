import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ViewEncapsulation} from '@angular/core';
@Component({
  selector: 'app-img-model',
  templateUrl: './img-model.component.html',
  styleUrls: ['./img-model.component.css'],
})
export class ImgModelComponent implements OnInit {
dialogImg
  constructor(public dialogRef: MatDialogRef<ImgModelComponent>, @Inject(MAT_DIALOG_DATA) public data: Gallery,) {
    dialogRef.disableClose = true;
    this.dialogImg = data;
   }

  ngOnInit(): void {
    
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
export interface Gallery {
  image_name: string;
  image: string;
  s3_image_url: string;
}