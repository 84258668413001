import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { GoogleAuthService } from 'src/app/services/google-auth.service';


@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  userInfo = {
    name: '',
    image: ''
  }

  constructor(private gAuth: GoogleAuthService,
              private router: Router) { }

  ngOnInit(): void {
    const obj = this.gAuth.getAccessToken();
    this.userInfo.name = obj['name'];
    this.userInfo.image = obj['image'];
  }

  navigateToUserPage() {
    this.router.navigate(['user'])
  }
  
  logOut() {
    this.gAuth.signOut();
    window.location.href = '/';
  }

}
