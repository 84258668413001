import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AdminService } from 'src/app/services/admin.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-latest-update',
  templateUrl: './latest-update.component.html',
  styleUrls: ['./latest-update.component.css']
})
export class LatestUpdateComponent implements OnInit {

  constructor(private ngxService: NgxUiLoaderService, private adminService: AdminService, private userService: UserService) { }
@Input() faqs;
@Input() empManuals;
@Input() newJoinees;

  ngOnInit(): void {
  }
  openFile(fileID) {
    this.ngxService.start();
    this.userService.getFilePresURL(fileID)
      .subscribe((resp: any) => {
        this.ngxService.stop();
        this.adminService.openFileByPresignedURL(resp.data.s3_file_url)
          .subscribe((res: any) => {
            var blob = new Blob([res], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(blob)
            window.open(fileURL, '_blank');
          })
      },
        (error) => {
          this.ngxService.stop();
          console.log(error);
        })
  }
}
