import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-emp-quotes',
  templateUrl: './emp-quotes.component.html',
  styleUrls: ['./emp-quotes.component.css']
})
export class EmpQuotesComponent implements OnInit {
  @Input() empQuotes;
  constructor() { }

  ngOnInit(): void {
  }

}
