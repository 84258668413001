<div class="galimg">
    <div class="gallarytxt">Flashback</div>
    <div class="imagegallery" *ngIf="gallery.length > 0">
        <ng-container *ngFor="let gal of gallery; let i = index">
            <div class="image">
                <ng-container *ngIf="gal.image;else other_gallary">
                    <img [attr.src]="gal.image" alt="{{i+1}} gallary" (click)="openImgModel(gal)">
                </ng-container>
                <ng-template #other_gallary><img class="def-img" src="assets/loading.gif">
                </ng-template>
            </div>
        </ng-container>
    </div>
    <div class="imagegalleryni" *ngIf="gallery.length == 0">
        No Flashback
    </div>
</div>