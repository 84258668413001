<div class="eventsarea">
    <div class="calender">
        <mat-calendar [selected]="selectedDate" (selectedChange)="selectedDate = $event; calendarSelectedDate($event)">
        </mat-calendar>
    </div>
    <div class="compevents">
        <div class="eventheader">
            Happenings
        </div>
        <div class="eventcontent" *ngIf="filteredEvents.length > 0">
            <ng-container *ngFor="let event of filteredEvents">
                <div class="eventtxt"><a [attr.href]="event.event_link" target="_blank"><span
                            *ngIf="event.title">{{event.title}}:</span> {{event.text}} <br />
                        <span>Date:</span> {{event.event_date | date}} <br />
                        <span>Time:</span> From:{{event.time_from}} To: {{event.time_to}}
                        (IST)
                    </a></div>
                <div class="devider"></div>
            </ng-container>
        </div>
        <div class="eventcontentni" *ngIf="filteredEvents.length == 0">
            No Happenings
        </div>
    </div>
    <div class="video">
        <div class="vidioheader">
            Showreels
        </div>
        <ng-container>
            <div class="videolink" *ngFor="let vl of (videoLink | slice: videoLink?.length - 1); let i = index">
                <ng-container *ngIf="vl.video_link;else img">
                    <ng-container *ngIf="vl.video_type == 'YOUTUBE'">
                        <iframe width="93%" height="100%" [attr.src]="vl.video_link+'?embedded=true'| safe"
                            allowfullscreen>
                        </iframe>
                    </ng-container>
                    <ng-container *ngIf="vl.video_type == 'LINKEDIN'">
                        <iframe width="93%" height="100%" [attr.src]="vl.video_link | safe" frameborder="0"
                            allowfullscreen="" title="Embedded post"></iframe>
                    </ng-container>
                    <ng-container *ngIf="vl.video_type == 'FACEBOOK'">
                        <fb-video [attr.href]="vl.video_link"></fb-video>
                    </ng-container>
                </ng-container>
                <ng-template #img>
                    <div class="videoimg">
                        <ng-container *ngIf="vl.image;else other_img"><img [src]="vl.image" alt="videoLink.image_name">
                        </ng-container>
                        <ng-template #other_img>
                            <img src="assets/loading.gif" alt="videoLink.image_name">
                        </ng-template>
                    </div>
                </ng-template>
            </div>
            <div class="videolink" *ngIf="videoLink.length == 0">
                No Showreels
            </div>
        </ng-container>
        <div class="footertxt">
            Regalix Reloaded From Home
        </div>
    </div>
    <div class="appreciations">
        <div class="appreciationheader">Accolades</div>
        <div class="appreciationtxt" *ngIf="appreciations.length > 0">
            <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
                <mdb-carousel-item *ngFor="let ap of appreciations">
                    <a [attr.href]="ap.appreciation_link" target="_blank">
                        <div class="empimg">
                            <ng-container *ngIf="ap.image;else apimg">
                                <img [attr.src]="ap.image" alt="em-img">
                            </ng-container>
                            <ng-template #apimg><img src="assets/loading.gif">
                            </ng-template>
                        </div>
                        {{ap.text}}
                    </a>
                </mdb-carousel-item>
            </mdb-carousel>
        </div>
        <div class="appreciationtxtnp" *ngIf="appreciations.length == 0">
            No Accolades
        </div>
    </div>
</div>