<div class="faqempmanual">
    <div class="faqs">
        <div class="faqhead">
            Help Center
        </div>
        <div class="faqcontent" *ngIf="faqs.length > 0">
            <mat-accordion>
                <mat-expansion-panel #panel hideToggle *ngFor="let faq of faqs; let i=index;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Q {{i+1}}. {{faq.question}}
                        </mat-panel-title>
                        <mat-panel-description style="justify-content: flex-end;">
                            <mat-icon>{{panel.expanded? 'remove' : 'add'}}</mat-icon>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p class="expl">{{faq.answer}}</p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="faqcontentni" *ngIf="faqs.length == 0">
            Help Center
        </div>
    </div>
    <div class="employeemanual">
        <div class="manualhead">
            E-Manual
        </div>
        <div class="manulacontent" *ngIf = "empManuals.length > 0">
            <div class="docs" *ngFor="let manual of empManuals">
                <img class="doc-file" src="assets/doc-file-format.svg" alt="doc-file">
                <a (click)="openFile(manual.id)">{{manual.name}}</a>
            </div>
        </div>
        <div class="manulacontentni" *ngIf = "empManuals.length == 0">
            No E Manual
        </div>

    </div>
    <div class="jobopening">
        <div class="jobopeningheade">
            Career Corner
        </div>
        <div class="jobopeningcontent">
            <div class="content" hidden>
                <div class="headtxt">Career Opportunity</div>
                <div class="subtxt">
                    <div>Help your friends and family members find a promising career opportunity.
                        Explore current openings</div>
                    <a type="button" class="apbutton" href="https://careers.regalix-inc.com/#!/joblist" target="_blank">Apply Now</a>
                </div>
            </div>
            <div class="opotunity">
                <div class="optxt">Help your friends and family members find a promising career opportunity.</div>
                <a type="button" class="apbutton" href="https://careers.regalix-inc.com/#!/joblist" target="_blank">Explore current openings >></a> 
            </div>
        </div>
    </div>
    <div class="newjoining">
        <div class="newjoinghead">
            Red Carpet

        </div>
        <div class="newjoincontent" *ngIf="newJoinees.length > 0">
            <div class="empbox" *ngFor="let new of newJoinees">
                <div class="empimg">
                    <img src="assets/profile.png" alt="profileimg">
                </div>
                <div class="employee">
                    <div class="empname">
                        {{new.name}}
                    </div>
                    <div class="empproject">
                        {{new.department}}
                    </div>
                </div>
            </div>

        </div>
        <div class="newjoincontentni" *ngIf="newJoinees.length == 0">
            No Red Carpet
        </div>
    </div>
</div>