import { Component, HostListener, OnInit } from '@angular/core';
import { ScriptService } from 'src/app/services/script.service';
import { UserService } from 'src/app/services/user.service';
declare var twttr: any;

@Component({
  selector: 'app-social-update',
  templateUrl: './social-update.component.html',
  styleUrls: ['./social-update.component.css']
})
export class SocialUpdateComponent implements OnInit {
  screenWidth;
  fbwidth = 410;
  flashNews: any;
  constructor(public scriptService: ScriptService, public userService: UserService) {
    this.onResize();
   }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
  ngOnInit(): void {
    if (this.screenWidth > 1400) {      
      this.fbwidth = 700
    }
    this.twitfed();
    this.getNews()
  }
  async twitfed() {
    await this.scriptService.load('twitter', 'facebook');
    twttr.widgets.load();
  }
  getNews() {
    this.userService.getNewsFlash().subscribe(
      (resp) => {
        this.flashNews = Object.values(resp).reverse()
      },
      (error) => {
        console.log(error)
      }
    )
  }
}
