<div class="quoteandimg" *ngIf="empQuotes.length > 0">
    <ng-container *ngFor="let quote of (empQuotes | slice: empQuotes?.length - 1); let i = index">
        <div class="quote">
            <div class="quote-head"><img src="assets/typography.svg" alt="inverted-coma">
                {{quote.title}}</div>
            <div class="quotear">
                <div class="quote">
                    “{{quote.text}}”
                </div>
                <div class="quoteby">
                    -{{quote.quote_by}}
                </div>
            </div>
        </div>
        <div class="quoteimg">
            <ng-container *ngIf="quote.image;else quoteimg">
                <img [attr.src]="quote.image" alt="{{quote.image_name}}" alt="qote-img">
            </ng-container>
            <ng-template #quoteimg><img src="assets/loading.gif">
            </ng-template>
        </div>

    </ng-container>
</div>
<div class="quoteandimgniten" *ngIf="empQuotes.length == 0">
    No Quotes
</div>
