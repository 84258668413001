import { Component, OnInit } from '@angular/core';
import { ScriptService } from 'src/app/services/script.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminService } from 'src/app/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GoogleAuthService } from 'src/app/services/google-auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ImgModelComponent } from '../img-model/img-model.component';
declare var twttr: any;
import { timer, interval } from 'rxjs';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit, OnDestroy {
  userInfo = {
    name: '',
    image: ''
  }

  empOverallData;
  banners;
  videoLink;
  empQuotes;
  notification;
  events;
  filteredEvents;
  birthdays;
  anniversary;
  filteredBirthdays;
  filteredAnniversary;
  newJoinees;
  appreciations;
  faqs;
  empManuals;
  tools;
  selectedDate = new Date();
  gallery;
  isAdmin = false;
  flexieURL;
  tokenFlexieLogin;
  timer;
  constructor(private gAuth: GoogleAuthService, private userService: UserService, public scriptService: ScriptService,
    private sanitizer: DomSanitizer, private adminService: AdminService,
    private spinner: NgxSpinnerService, private ngxService: NgxUiLoaderService, public router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    const obj = this.gAuth.getAccessToken();
    this.userInfo.name = obj['name'];
    this.userInfo.image = obj['image'];
    if (obj['roles'] == 'Admin') {
      this.isAdmin = true;
    }
    this.getOverAllEmpD();
    this.timer = interval(60*60*1000).subscribe(() => {
      this.getOverAllEmpD();
    })
  }
  ngOnDestroy() {
    this.timer.unsubscribe()
  }

  navigateToAdminPage() {
    this.router.navigate(['admin']);
  }
  logOut() {
    this.gAuth.signOut();
    window.location.href = '/';
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate()
    return [month, day].join('-');
  }

  format(date) {
    var d = new Date(date),
      year = '' + (d.getFullYear()),
      month = '' + (d.getMonth() + 1),
      m = (month.length > 1) ? month : '0' + month,
      day = '' + d.getDate(),
      x = (day.length > 1) ? day : '0' + day
    return [year, m, x].join('-');
  }
  calendarSelectedDate(date) {
    let y = this.format(date)
    let monthDay = this.formatDate(date)
    this.filteredEvents = [];
    this.filteredEvents = this.events.filter(data => data.event_date === y)
  }

  getOverAllEmpD() {
    this.spinner.show();
    this.userService.getUserData().subscribe(
      (resp: any) => {
        this.spinner.hide();
        this.empOverallData = resp.data;
        this.banners = resp.data.banner;
        this.flexieURL = resp.data.flexie_login.url;
        this.tokenFlexieLogin = resp.data.flexie_login.token;
        if (resp.data.banner) {
          for (let index = 0; index < resp.data.banner.length; index++) {
            if (resp.data.banner[index].s3_image_url) {
              this.userService.getImageFroms3(resp.data.banner[index].s3_image_url).subscribe(
                (res: any) => {
                  let blob = new Blob([res], { type: 'image/jpeg' });
                  let url = window.URL.createObjectURL(blob)
                  this.banners[index].image = this.sanitizer.bypassSecurityTrustUrl(url)
                }
              )
            }
            if (resp.data.banner[index].s3_video_url) {
              this.userService.getVideoFroms3(resp.data.banner[index].s3_video_url).subscribe(
                (res: any) => {
                  let blob = new Blob([res], { type: 'video/mp4' });
                  let url = window.URL.createObjectURL(blob)
                  this.banners[index].s3_video_url = this.sanitizer.bypassSecurityTrustUrl(url)
                }
              )
            }
          }
        }
        this.videoLink = resp.data.video_link;
        if (resp.data.video_link) {
          for (let index = 0; index < resp.data.video_link.length; index++) {
            this.userService.getImageFroms3(resp.data.video_link[index].s3_image_url)
              .subscribe((res: any) => {
                let blob = new Blob([res], { type: 'image/jpeg' });
                let url = window.URL.createObjectURL(blob)
                this.videoLink[index].image = this.sanitizer.bypassSecurityTrustUrl(url)
              })
          }
        }

        this.empQuotes = resp.data.emp_quote;
        if (resp.data.emp_quote) {
          for (let index = 0; index < resp.data.emp_quote.length; index++) {
            this.userService.getImageFroms3(resp.data.emp_quote[index].s3_image_url)
              .subscribe((res: any) => {
                let blob = new Blob([res], { type: 'image/jpeg' });
                let url = window.URL.createObjectURL(blob)
                this.empQuotes[index].image = this.sanitizer.bypassSecurityTrustUrl(url)
              })
          }
        }
        this.notification = resp.data.notification;
        this.events = resp.data.event;
        const date = this.format(this.selectedDate);
        this.filteredEvents = this.events.filter(data => data.event_date === date);
        this.birthdays = resp.data.dob_data;
        this.anniversary = resp.data.work_anniversary;
        const monthDay = this.formatDate(this.selectedDate)
        this.filteredBirthdays = this.birthdays.filter(data => data.month_day === monthDay)
        this.filteredAnniversary = this.anniversary.filter(data => data.month_day === monthDay)
        this.newJoinees = resp.data.doj_data;
        this.appreciations = resp.data.client_appreciation;
        if (resp.data.client_appreciation) {
          for (let index = 0; index < resp.data.client_appreciation.length; index++) {
            this.userService.getImageFroms3(resp.data.client_appreciation[index].s3_image_url)
              .subscribe((res: any) => {
                let blob = new Blob([res], { type: 'image/jpeg' });
                let url = window.URL.createObjectURL(blob)
                this.appreciations[index].image = this.sanitizer.bypassSecurityTrustUrl(url)
              })
          }
        }
        this.faqs = resp.data.faq;
        this.empManuals = resp.data.emp_manual;
        this.gallery = resp.data.gallery;
        this.tools = resp.data.tool;
        if (resp.data) {
          for (let index = 0; index < resp.data.gallery.length; index++) {
            this.userService.getImageFroms3(resp.data.gallery[index].s3_image_url)
              .subscribe((res: any) => {
                let blob = new Blob([res], { type: 'image/jpeg' });
                let url = window.URL.createObjectURL(blob)
                this.gallery[index].image = this.sanitizer.bypassSecurityTrustUrl(url)
              })
          }
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    )
  }
  openFile(fileID) {
    this.ngxService.start();
    this.userService.getFilePresURL(fileID)
      .subscribe((resp: any) => {
        this.ngxService.stop();
        this.adminService.openFileByPresignedURL(resp.data.s3_file_url)
          .subscribe((res: any) => {
            var blob = new Blob([res], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(blob)
            window.open(fileURL, '_blank');
          })
      },
        (error) => {
          this.ngxService.stop();
          console.log(error);
        })
  }
}

