<app-admin-header></app-admin-header>
<mat-sidenav-container class="side-container" autosize>
    <mat-sidenav #drawer class="example-sidenav" mode="side" opened>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin/banner-list']" routerLinkActive="active"><span>Upload
                    Banner</span></a>
        </mat-nav-list>
        <mat-divider></mat-divider>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin/tools']" routerLinkActive="active"><span>Tools
                    </span></a>
        </mat-nav-list>
        <mat-divider></mat-divider>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin/notification']" routerLinkActive="active"><span>Buzz-Feed</span></a>
        </mat-nav-list>
        <mat-divider></mat-divider>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin/video-link']" routerLinkActive="active"><span>
                Showreels</span></a>
        </mat-nav-list>
        <mat-divider></mat-divider>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin/employee-quotes']" routerLinkActive="active"><span>
                Rockstar Mantras</span></a>
        </mat-nav-list>
        <mat-divider></mat-divider>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin/events']" routerLinkActive="active"><span>Happenings
                    </span></a>
        </mat-nav-list>
        <mat-divider></mat-divider>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin/client-appreciation']" routerLinkActive="active"><span>
                Accolades</span></a>
        </mat-nav-list>
        <mat-divider></mat-divider>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin/faq']" routerLinkActive="active"><span>Help Center
                    </span></a>
        </mat-nav-list>
        <mat-divider></mat-divider>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin/employee-manual']" routerLinkActive="active"><span>E-Manual
                    </span></a>
        </mat-nav-list>
        <mat-divider></mat-divider>
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/admin/gallery']" routerLinkActive="active"><span>Flashback
                    </span></a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>