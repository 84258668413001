import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuthService } from 'src/app/services/google-auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userInfo = {
    name: '',
    image: ''
  }
  isAdmin = false;
  @Input() tools;
  @Input() flexieURL;
  @Input() tokenFlexieLogin;
  constructor(private gAuth: GoogleAuthService,
    private router: Router, public userService: UserService) { }

  ngOnInit(): void {
    const obj = this.gAuth.getAccessToken();
    this.userInfo.name = obj['name'];
    this.userInfo.image = obj['image'];
    if (obj['roles'] == 'Admin') {
      this.isAdmin = true;
    }
  }
  navigateToAdminPage() {
    this.router.navigate(['admin']);
  }
  flexieLogin(flexieLogin, event) {
    event.target.submit();
  }
  logOut() {
    this.gAuth.signOut();
    window.location.href = '/';
  }
}
