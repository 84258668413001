import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuthService } from 'src/app/services/google-auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-navbar',
  templateUrl: './user-navbar.component.html',
  styleUrls: ['./user-navbar.component.css']
})
export class UserNavbarComponent implements OnInit {
  userInfo = {
    name: '',
    image: ''
  }

  tools_products = [
    {
      name: 'Tools',
      links: [
          {a_name: 'My Savary', href: 'https://mysavaari.regalix.com'},
          {a_name: 'EWS', href: 'https://rx-ews.regalixdigital.com'},
          {a_name: 'WFM', href: 'https://wfm3.regalixdigital.com'},
          {a_name: 'MoM', href: ''},
          {a_name: 'Meister', href: ''}
    ],
    },
    {
      name: 'Nytro.ai',
      links: [
        {a_name: 'Some text..', href: 'https://www.nytro.ai'}
      ]
    },
    {
      name: 'Regalix TV',
      links: [
        {a_name: 'Some text..', href: 'https://www.regalix.tv'}
      ]
    }
  ]
  mobileSideNav: boolean;
  showCloseIcon: boolean;
  flexieURL;
  tokenFlexieLogin;
  isAdmin = false;
  @Input() tools;
  constructor(private gAuth: GoogleAuthService,
              private router: Router, public userService: UserService) { }

  ngOnInit(): void {
    const obj = this.gAuth.getAccessToken();
    this.userInfo.name = obj['name'];
    this.userInfo.image = obj['image'];
    if (obj['roles'] == 'Admin') {
      this.isAdmin = true;
    }
    this.userService.getTokenFlexie()
    .subscribe((res: any) => {
      if (res.status) {
        this.flexieURL = res.data.url;
        this.tokenFlexieLogin = res.data.token;
      }
    })
  }

  navigateToAdminPage() {
    this.router.navigate(['admin']);
  }

  showMobileSideNav() {
    this.mobileSideNav = true;
    this.showCloseIcon = true;
  }
  hideMobileSideNav() {
    this.mobileSideNav = false;
    this.showCloseIcon = false;
  }
  logOut() {
    this.gAuth.signOut();
    window.location.href = '/';
  }
  flexieLogin(flexieLogin, event) {
    event.target.submit();
  }
}
