<div class="socialupdates">
    <div class="latestnews">
        <div class="newsheader">News Flash</div>
        <div class="newscontent">
            <div class="news" *ngFor = "let news of flashNews">
                <div class="newsdateplace">
                    {{news.published_date}} | {{news.published_place}}
                </div>
                <a [attr.href]="news.permalink" target="_blank">
                <div class="newscnt" [innerHTML]="news.title">
                </div>
                </a>
            </div>
        </div>
    </div>
    <div class="facebookfeed">
        <div class="fb-page" data-href="https://www.facebook.com/Regalix" data-tabs="timeline" data-height="615"
        [attr.data-width]="fbwidth" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true"
            data-show-facepile="true">
            <blockquote cite="https://www.facebook.com/Regalix" class="fb-xfbml-parse-ignore"><a
                    href="https://www.facebook.com/Regalix" target="_blank">Regalix</a></blockquote>
        </div>
    </div>
    <div class="tweeterfeed">
        <div id="second_div" class="row ml-0 mr-0">
            <a class="twitter-timeline" href="https://twitter.com/Regalix?ref_src=twsrc%5Etfw" data-height="614">Tweets
                by
                Regalix</a>
        </div>
    </div>
</div>