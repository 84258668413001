import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-notifications',
  templateUrl: './company-notifications.component.html',
  styleUrls: ['./company-notifications.component.css']
})
export class CompanyNotificationsComponent implements OnInit {
@Input() notification;
  constructor() { }

  ngOnInit(): void {
  }

}
