import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tick } from '@angular/core/testing';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  backendApi = environment.apiBaseUrl
  pageTitle;
  constructor(private http: HttpClient) { }

  // bannner services
  getBanners() {
    return this.http.get(this.backendApi + 'admin/banners')
  }
  getBannerImageById(bannerID) {
    return this.http.get(this.backendApi + 'admin/banners?banner_id=' + bannerID + '&req_image=yes');
  }

  getBannerByID(bannerID) {
    return this.http.get(this.backendApi + 'admin/banners?banner_id=' + bannerID);
  }

  addBanner(bannerData) {
    return this.http.post(this.backendApi + 'admin/banners', bannerData);
  }
  editBanner(bannerID, bannerData) {
    return this.http.put(this.backendApi + 'admin/banners?banner_id=' + bannerID, bannerData);
  }
  deleteBanner(bannerID) {
    return this.http.delete(this.backendApi+ 'admin/banners?banner_id=' + bannerID)
  }

  // notifications services
  getNotifications() {
    return this.http.get(this.backendApi + 'admin/notifications')
  }
  postNotifications(notification) {
    return this.http.post(this.backendApi + 'admin/notifications', notification)
  }
  editNotification(id, notification) {
    return this.http.put(this.backendApi + 'admin/notifications?notify_id=' + id, notification)
  }
  getNotificationById(id) {
    return this.http.get(this.backendApi + 'admin/notifications?notify_id=' + id)
  }
  deleteNotification(id) {
    return this.http.delete(this.backendApi + 'admin/notifications?notify_id=' + id)
  }
  // tools services
  getTool() {
    return this.http.get(this.backendApi + 'admin/tools')
  }
  postTools(data) {
    return this.http.post(this.backendApi + 'admin/tools', data)
  }
  editTool(id, data) {
    return this.http.put(this.backendApi + 'admin/tools?tool_id=' + id, data)
  }
  getToolById(id) {
    return this.http.get(this.backendApi + 'admin/tools?tool_id=' + id)
  }
  deleteTools(id) {
    return this.http.delete(this.backendApi + 'admin/tools?tool_id=' + id)
  }
  // vedio-link services
  getVideoLink() {
    return this.http.get(this.backendApi + 'admin/video-link')
  }
  postVideoLik(data) {
    return this.http.post(this.backendApi + 'admin/video-link', data)
  }
  editVideoLink(id, data) {
    return this.http.put(this.backendApi + 'admin/video-link?vlink_id=' + id, data)
  }
  getVideoLinkById(id) {
    return this.http.get(this.backendApi + 'admin/video-link?vlink_id=' + id)
  }
  deleteVideoLink(id) {
    return this.http.delete(this.backendApi + 'admin/video-link?vlink_id=' + id)
  }
  // employee quote services
  getQuote() {
    return this.http.get(this.backendApi + 'admin/emp-quotes')
  }
  getQuoteById(id) {
    return this.http.get(this.backendApi + 'admin/emp-quotes?emp_quote_id=' + id)
  }
  postQuote(data) {
    return this.http.post(this.backendApi + 'admin/emp-quotes', data)
  }
  editQuote(id, data) {
    return this.http.put(this.backendApi + 'admin/emp-quotes?emp_quote_id=' + id, data)
  }
  deleteQuote(id) {
    return this.http.delete(this.backendApi + 'admin/emp-quotes?emp_quote_id=' + id)
  }
  // event services
  getEvent() {
    return this.http.get(this.backendApi + 'admin/events')
  }
  getEventById(id) {
    return this.http.get(this.backendApi + 'admin/events?event_id=' + id)
  }
  postEvent(data) {
    return this.http.post(this.backendApi + 'admin/events', data)
  }
  editEvent(id, data) {
    return this.http.put(this.backendApi + 'admin/events?event_id=' + id, data)
  }
  deleteEvent(id) {
    return this.http.delete(this.backendApi + 'admin/events?event_id=' + id)
  }
  // appreciations service
  getAppreciations() {
    return this.http.get(this.backendApi + 'admin/client-appreciation');
  }
  getAprecitionById(id) {
    return this.http.get(this.backendApi + 'admin/client-appreciation?client_appr_id=' + id)
  }
  postAppreciation(data) {
    return this.http.post(this.backendApi + 'admin/client-appreciation', data)
  }
  editApreciations(id, data) {
    return this.http.put(this.backendApi + 'admin/client-appreciation?client_appr_id=' + id, data)
  }
  deleteApreciation(id) {
    return this.http.delete(this.backendApi + 'admin/client-appreciation?client_appr_id=' + id)
  }
  // faq service
  getFaq() {
    return this.http.get(this.backendApi + 'admin/faqs')
  }
  getFaqById(id) {
    return this.http.get(this.backendApi + 'admin/faqs?faq_id=' + id)
  }
  postFaq(data) {
    return this.http.post(this.backendApi + 'admin/faqs', data)
  }
  editFaq(id, data) {
    return this.http.put(this.backendApi + 'admin/faqs?faq_id=' + id, data)
  }
  deleteFaq(id) {
    return this.http.delete(this.backendApi + 'admin/faqs?faq_id=' + id)
  }
  // employee manual service
  getEmpManual() {
    return this.http.get(this.backendApi + 'admin/emp-manual')
  }
  getEmpManualById(id) {
    return this.http.get(this.backendApi + 'admin/emp-manual?emp_manual_id=' + id)
  }
  postEmpManual(data) {
    return this.http.post(this.backendApi + 'admin/emp-manual', data)
  }
  editEmpManual(id, data) {
    return this.http.put(this.backendApi + 'admin/emp-manual?emp_manual_id=' + id, data)
  }
  deleteEmpManual(id) {
    return this.http.delete(this.backendApi + 'admin/emp-manual?emp_manual_id=' + id)
  }

  getFilePresURL(id) {
    return this.http.get(this.backendApi + 'admin/emp-manual?emp_manual_id=' + id)
  }

  openFileByPresignedURL(url) {
    console.log(url);
    return this.http.get(url, {responseType: 'blob'})
  }

  
  // gallery services
  getGallery() {
    return this.http.get(this.backendApi + 'admin/gallery')
  }
  getGalleryById(id) {
    return this.http.get(this.backendApi + 'admin/gallery?gallery_id=' + id)
  }
  postGallery(data) {
    return this.http.post(this.backendApi + 'admin/gallery', data)
  }
  editGallery(id, data) {
    return this.http.put(this.backendApi + 'admin/gallery?gallery_id=' + id, data)
  }
  deleteGallery(id) {
    return this.http.delete(this.backendApi + 'admin/gallery?gallery_id=' + id)
  }

  getImageFroms3(url) {
    return this.http.get(url, {responseType: 'blob'})
  }
}
