<div class="header">
    <div class="logo">
      <a routerLink="">
        <img src="../../../assets/Group 13747.svg" alt="product-logo" style="height: 42px;">
      </a>
    </div>
    <div class="perfonal-info">

      <div class="profile-img">
        <img class="media-md" [src]="userInfo.image" onerror="this.onerror=null;this.src='../../../assets/profile.png';" alt="profile-pic">
        <img class="media-sm" [src]="userInfo.image" onerror="this.onerror=null;this.src='../../../assets/profile.png';" [matMenuTriggerFor]="menu" alt="profile-pic">
      </div>
      <div class="name-drop">
        <button mat-button [matMenuTriggerFor]="menu">{{userInfo.name}} <i class="material-icons">
            keyboard_arrow_down
          </i></button>
          <mat-menu #menu="matMenu">
            <div>
                <button mat-menu-item (click)="navigateToUserPage()">Home</button>
                <button mat-menu-item (click)="logOut()">Logout</button>
            </div>
        </mat-menu>
      </div>
  
    </div>
  </div>