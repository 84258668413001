import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FacebookModule } from 'ngx-facebook';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule  } from './shared/shared.module';


import { GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import  { environment } from '../environments/environment';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';

import { TokenInterceptorService } from './services/interceptor/token-interceptor.service';

import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { SafePipe } from './safe.pipe';
import { UserNavbarComponent } from './user/user-navbar/user-navbar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ImgModelComponent } from './user/img-model/img-model.component';
import { BannerComponent } from './user/components/banner/banner.component';
import { HeaderComponent } from './user/components/header/header.component';
import { FooterComponent } from './user/components/footer/footer.component';
import { UserViewComponent } from './user/user-view/user-view.component';
import { EmpQuotesComponent } from './user/components/emp-quotes/emp-quotes.component';
import { CompanyNotificationsComponent } from './user/components/company-notifications/company-notifications.component';
import { CompanyEventsComponent } from './user/components/company-events/company-events.component';
import { CompanyVideosComponent } from './user/components/company-videos/company-videos.component';
import { LatestUpdateComponent } from './user/components/latest-update/latest-update.component';
import { SocialUpdateComponent } from './user/components/social-update/social-update.component';
import { PicGalleryComponent } from './user/components/pic-gallery/pic-gallery.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SafePipe,
    UserNavbarComponent,
    PageNotFoundComponent,
    ImgModelComponent,
    BannerComponent,
    HeaderComponent,
    FooterComponent,
    UserViewComponent,
    EmpQuotesComponent,
    CompanyNotificationsComponent,
    CompanyEventsComponent,
    CompanyVideosComponent,
    LatestUpdateComponent,
    SocialUpdateComponent,
    PicGalleryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SocialLoginModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    MDBBootstrapModule.forRoot(),
    FacebookModule.forRoot()
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleLoginClientId)
          }
        ]
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
