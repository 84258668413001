import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImgModelComponent } from '../../img-model/img-model.component';

@Component({
  selector: 'app-pic-gallery',
  templateUrl: './pic-gallery.component.html',
  styleUrls: ['./pic-gallery.component.css']
})
export class PicGalleryComponent implements OnInit {
  @Input() gallery;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  openImgModel(element) {
    this.dialog.open(ImgModelComponent, {
      width: '80%',
      height: '80%',
      data: element,
      autoFocus: false,
      panelClass: 'myapp-no-padding-dialog',
    }).afterClosed().subscribe(
      (result) => {
        console.log(result);
        
      }
    )
  }
}
