import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SocialAuthService } from 'angularx-social-login';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {

  public backendApi = environment.apiBaseUrl;

  constructor(private authService: SocialAuthService,
              private http: HttpClient) { }

  signOut() {
    this.authService.signOut();
    localStorage.clear();
  }

  idpPermission(token, email) {
    return this.http.post(this.backendApi + 'idp-permission?email=' + email, JSON.stringify(token));
  }

  getAccessToken() {
    return JSON.parse(localStorage.getItem('user'))
  }

  isLoggedIn() {
    return !!localStorage.getItem('user');
  }
}


