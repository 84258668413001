import { Component, Input, OnInit } from '@angular/core';
import { GoogleAuthService } from 'src/app/services/google-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  userInfo = {
    name: '',
    image: ''
  }

  tools_products = [
    {
      name: 'Tools',
      links: [
          {a_name: 'My Savary', href: 'https://mysavaari.regalix.com'},
          {a_name: 'EWS', href: 'https://rx-ews.regalixdigital.com'},
          {a_name: 'WFM', href: 'https://wfm3.regalixdigital.com'},
          {a_name: 'MoM', href: ''},
          {a_name: 'Meister', href: ''}
    ],
    },
    {
      name: 'Nytro.ai',
      links: [
        {a_name: 'Some text..', href: 'https://www.nytro.ai'}
      ]
    },
    {
      name: 'Regalix TV',
      links: [
        {a_name: 'Some text..', href: 'https://www.regalix.tv'}
      ]
    }
  ]
  mobileSideNav: boolean;
  showCloseIcon: boolean;
  @Input() tools;
  constructor(private gAuth: GoogleAuthService,
              private router: Router) { }

  ngOnInit(): void {
    const obj = this.gAuth.getAccessToken();
    this.userInfo.name = obj['name'];
    this.userInfo.image = obj['image'];
  }
  showMobileSideNav() {
    this.mobileSideNav = true;
    this.showCloseIcon = true;
  }
  hideMobileSideNav() {
    this.mobileSideNav = false;
    this.showCloseIcon = false;
  }
  logOut() {
    this.gAuth.signOut();
    window.location.href = '/';
  }

}
