<h1 mat-dialog-title>
    {{title}}
  </h1>
   
  <div mat-dialog-content>
    <mat-card>
    <p>{{msg}}</p>
  </mat-card>
  </div>
   
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="closeDialog()">OK</button>
  </div>